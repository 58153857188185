.total {
    background-color: gold;
}

table,
tr,
td {
    border: 1px solid black;
    border-collapse: collapse;
}

td {
    width: 5vw;
    height: 5vh;
    text-align: center;
}